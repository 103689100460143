import axios from '@axios'
import common from '@/libs/common'

export default function verndorsService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  // General ----------------------------------------
  const fetchVendors = (data, callback) => {
    axios
      .get(`${API_URL}/Proveedores`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createVendor = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Proveedores`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  // {
  //   axios
  //     .post(`${API_URL}/Proveedores`, data)
  //     then(reponse => throwSuccess(response))
  //     // .then(response => {
  //     //   callback(response.data)
  //     // })
  //     .catch(error => throwError(error))
  // }
  const fetchVendor = ({ idVendor }, callback) => {
    axios
      .get(`${API_URL}/Proveedores/${idVendor}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateVendor = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Proveedores/${data.proveedorId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteVendor = (idVendor, callback) => {
    axios
      .delete(`${API_URL}/Proveedores/${idVendor}`)
      .then(response => {
        throwSuccess(response)
        callback(response)
      })
      .catch(error => {
        throwError(error)
      })
  }

  const fetchVendorExcel = (data, callback) => {
    axios
      .get(`${API_URL}/Proveedores/ExportarPlantillaExcel`, { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchVendorShips = (data, callback) => {
    axios
      .get(`${API_URL}/Proveedores/Embarques`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchVendorShip = ({ idShip }, callback) => {
    axios
      .get(`${API_URL}/Proveedores/Embarques/${idShip}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createVendorShip = (data, callback) => {
    axios
      .post(`${API_URL}/Proveedores/Embarques`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchVendorShipInvoices = ({ embarqueId }, callback) => {
    axios
      .get(`${API_URL}/Proveedores/Embarques/${embarqueId}/Facturas`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchVendorShipInvoiceItems = ({ facturaId }, callback) => {
    axios
      .get(`${API_URL}/Proveedores/Embarques/Factura/${facturaId}/Partidas`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchVendorShipAwbs = ({ embarqueId }, callback) => {
    axios
      .get(`${API_URL}/Proveedores/Embarques/${embarqueId}/Guias`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchVendorShipDocuments = ({ embarqueId }, callback) => {
    axios
      .get(`${API_URL}/Proveedores/Embarques/${embarqueId}/Documentos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  return {
    fetchVendors,
    createVendor,
    fetchVendor,
    updateVendor,
    deleteVendor,
    fetchVendorExcel,
    fetchVendorShips,
    fetchVendorShip,
    createVendorShip,
    fetchVendorShipInvoices,
    fetchVendorShipAwbs,
    fetchVendorShipDocuments,
    fetchVendorShipInvoiceItems,
  }
}
