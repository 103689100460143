<template>
  <component :is="vendorData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="vendorData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('AppVendor.edit.errorFetchingUserData') }}
      </h4>
      <div class="alert-body">
        {{ $t('AppVendor.edit.noUserFound') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-vendors-list'}"
        >
          {{ $t('AppVendor.edit.userList') }}
        </b-link>
        {{ $t('AppVendor.edit.forOtherUsers') }}
      </div>
    </b-alert>

    <b-tabs
      v-if="vendorData"
      pills
    >

      <!-- Tab: Vendor -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppVendor.edit.Vendor') }}</span>
        </template>
        <vendor-edit-tab-Vendor
          :vendor-data="vendorData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import vendorStoreModule from '../vendorStoreModule'
import VendorEditTabVendor from './VendorEditTabVendor.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    VendorEditTabVendor,
  },
  setup() {
    const vendorData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-vendor'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, vendorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-vendor/fetchVendor', { proveedorId: router.currentRoute.params.proveedorId })
      .then(response => { vendorData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          vendorData.value = undefined
        }
      })

    return {
      vendorData,
    }
  },
}
</script>

<style>

</style>
